.page_home .init
{
	background: linear-gradient(
		rgba(0, 0, 0, 0.70), 
		rgba(0, 0, 0, 0.70)
	), url('/public/assets/home.jpeg') no-repeat center center;
	background-size: cover;
}

.page_home .end_page
{
	background: #232323;
}