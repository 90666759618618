:root
{
	--fontFamily: 'Quicksand', sans-serif;
	--fontFamily2: 'Raleway', sans-serif;

	--primary: #8dc63f;
	--secondary: #00aa9d;
}

.font_family_2
{
	font-family: var(--fontFamily2) !important;
}

.primary
{
	color: var(--primary) !important;
}

.background_primary
{
	background-color: var(--primary) !important;
}

.secondary
{
	color: var(--secondary) !important;
}

.background_secondary
{
	background-color: var(--secondary) !important;
}